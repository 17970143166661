<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <b>Condition</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="4">
              <CSelect
                label="Date"
                :options="dateOptions"
                :value="dateOptionSelected"
                @change="dateOptionChanged($event)"
              />
            </CCol>
            <CCol sm="4" v-if="dateOptionSelected === 'custom'">
              <CInput
                label="Start Date"
                type="date"
                v-model="dateOptionStart"
                @change="dateOptionStartChanged($event)"
              />
            </CCol>
            <CCol sm="4" v-if="dateOptionSelected === 'custom'">
              <CInput
                label="End Date"
                type="date"
                v-model="dateOptionEnd"
                @change="dateOptionEndChanged($event)"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CButton
                color="primary"
                @click="applyOptionClick"
                >
                Apply
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <b>Aggregation Information</b>
        </CCardHeader>
        <CCardBody>
          <dl class="row">
            <dt class="col-sm-3">Total Cost</dt>
            <dd class="col-sm-9">
              <b> {{ totalCost }} </b>
            </dd>
          </dl>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <b>Boardcast History</b>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            striped
            border
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :itemsPerPageSelect="true"
            :loading="loading"
            @pagination-change="paginationChanged">
              <template #detail="{item}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    variant="outline"
                    size="sm"
                    @click="detailClicked(item)">
                    Detail
                  </CButton>
                </td>
              </template>
          </CDataTable>
          <CPagination
            :activePage.sync="currPage"
            :pages.sync="totalPage"
            align="center"
            @update:activePage="pageChanged"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'
import TimeUtils from '../../common/TimeUtils'

const dateOptions = [
  { value: '7', label: 'Last 7 days' },
  { value: '30', label: 'Last 30 days' },
  { value: 'custom', label: 'Custom' },
  { value: 'all_time', label: 'All Time' },
];

const fields = [
  { key: '_id', label: 'ID', _style: 'width:50px' },
  { key: 'user_id', label: 'Created By' },
  { key: 'app_ids', label: 'List IDs' },
  { key: 'total_users', label: 'Total Users'},
  { key: 'total_cost', label: 'Cost'},
  { key: 'status', label: 'Status'},
  { key: 'time_created', label: 'Time Created'},
  { key: 'time_updated', label: 'Time Updated'},
  { 
    key: 'detail', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
]

export default {
  name: 'BoardcastHistory',
  created() {
    this.initParams();
    this.getBroadcastHistory(0, this.perPage);
  },
  data () {
    return {
      dateOptions,
      dateOptionSelected: dateOptions[0].value,
      dateOptionStart: "",
      dateOptionEnd: "",

      fields,
      items: [],
      totalPage: 0,
      currPage: 1,
      perPage: 10,

      brcAggData: {},

      loading: false,
    }
  },
  computed: {
    totalCost: function() {
      var totalCost = this.brcAggData?.total_cost;
      if (totalCost) {
        return totalCost;
      } else {
        return 0;
      }
    }
  },
  methods: {
    initParams() {
      if (this.$route.query.date_option) {
        this.dateOptionSelected = this.$route.query.date_option;
      }
      this.dateOptionStart = this.$route.query.start_date;
      this.dateOptionEnd = this.$route.query.end_date;
    },
    resetData() {
      this.items.splice(0, this.items.length);
    },
    getBroadcastHistory(from, size) {
      var _this = this;
      _this.resetData();
      _this.loading = true;

      var startTime = null;
      var endTime = null;

      if (_this.dateOptionSelected === "7") {
        startTime = TimeUtils.getUnixTime(-7);
        endTime = TimeUtils.getUnixTime(0);
      } else if (_this.dateOptionSelected === "30") {
        startTime = TimeUtils.getUnixTime(-30);
        endTime = TimeUtils.getUnixTime(0);
      } else if (_this.dateOptionSelected === "custom") {
        if (this.dateOptionStart) {
          startTime = TimeUtils.getUnixStartOfDay2(this.dateOptionStart, "MM-DD-YYYY");
        }
        if (this.dateOptionEnd) {
          endTime = TimeUtils.getUnixEndOfDay2(this.dateOptionEnd, "MM-DD-YYYY");
        }
      } else if (_this.dateOptionSelected === "all_time") {
        // do nothing
      }
      
      AxiosBase.get("/broadcast/history", {
        params: {
          start_time: startTime,
          end_time: endTime,
          from: from,
          size: size
        }
      })
      .catch(function(error) {
        console.log(error);
        _this.loading = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resBroadcastHistory = res?.data?.value?.items;
        var total = res?.data?.value?.total;
        var brcAggData = res?.data?.value?.agg;

        if (resCode >= 0 && resBroadcastHistory) {
          for (var item of resBroadcastHistory) {
            var formatedItem = item;
            if (item.condition) {
              formatedItem.app_ids = item.condition.app_ids;
            }
            if (item.progress) {
              formatedItem.total_users = item.progress.total_users;
              formatedItem.status = item.progress.status;
            }
            if (item.result && item.result.total_cost) {
              formatedItem.total_cost = `${item.result.total_cost}$`;
            } else {
              formatedItem.total_cost = "0$";
            }
            formatedItem.time_created = TimeUtils.formatTime(item.time_created);
            formatedItem.time_updated = TimeUtils.formatTime(item.time_updated);
            _this.items.push(formatedItem);
          }

          _this.totalPage = parseInt(total / size) + 1;
          _this.brcAggData = brcAggData;
        } else {
          // show errors
          console.log("errors: " + JSON.stringify(res))
        }
        _this.loading = false;
      });
    },
    pageChanged(pageNumber, reduced) {
      this.getBroadcastHistory(this.perPage * (pageNumber - 1), this.perPage);
    },
    paginationChanged(perPage) {
      this.perPage = perPage;
      this.getBroadcastHistory(0, this.perPage);
    },
    detailClicked(item) {
      this.$router.push(`/broadcast/historydetail/${item._id}`);
    },
    dateOptionChanged(event) {
      if (event && event.target) {
        this.dateOptionSelected = event.target.value;
        this.$router.push({query: {date_option: event.target.value}});
      }
      return true;
    },
    dateOptionStartChanged(event) {
      this.$router.push({query: {...this.$route.query, start_date: event}});
      this.dateOptionStart = event;
    },
    dateOptionEndChanged(event) {
      this.$router.push({query: {...this.$route.query, end_date: event}});
      this.dateOptionEnd = event;
    },
    applyOptionClick() {
      this.getBroadcastHistory(0, this.perPage);
    }
  }
}
</script>
